import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { QmStatus } from './Status.model';
import axios from 'axios';
import { properties } from '../../app/properties'
import { Location } from './Location.model';

interface StatusState {
  status: Array<QmStatus>;
  location: Location;
  lastTime?: number;
}


const initialState: StatusState = {
  status: [],
  location: {},
  lastTime: undefined
};

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeState: (state, newStatus: PayloadAction<Array<QmStatus>>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = newStatus.payload;
      state.lastTime = new Date().getTime();
    },
    changeLocation: (state, newLocation: PayloadAction<Location>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.location = newLocation.payload;
    },
  },
});

export const { changeState, changeLocation } = statusSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const fetchNewStatus = (locationCode: string): AppThunk => dispatch => {
  axios.get<Array<QmStatus>>(properties.serverUrl + "status/" + locationCode).then(v => dispatch(changeState(v.data)));
};


export const fetchLocation = (locationCode: string): AppThunk => dispatch => {
  axios.get<Location>(properties.serverUrl + "locations/" + locationCode).then(v => dispatch(changeLocation(v.data)));
};


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectStatus = (state: RootState): StatusState => state.status;

export default statusSlice.reducer;
