import React from 'react';
//import logo from './logo.svg';
//import { Counter } from './features/counter/Counter';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from "./Router"
import logo from './galicak.png';

function App() {
  return (
    <Router>
    <div className="App">
        {/*<header className="App-header">*/}
        {/*    <img src={logo} className="App-logo" alt="logo"/>*/}
        {/*    <Counter/>*/}
        {/*</header>*/}
        <AppRoutes/>
        <footer>
        <div  className="brand-icon">
          <img src={logo} alt="Logo" />&nbsp; Galičak d.o.o.
        </div>
        </footer>
    </div>
    
    </Router>
);
}

export default App;
