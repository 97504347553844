import {Switch, Route} from 'react-router-dom';
import * as React from 'react';
import {Status} from "./features/status/Status"

const Routes = () => (
    <Switch>
        <Route exact path="/status/:locationCode">
            <Status/>
        </Route>
        {/*<Route path="/dashboard">*/}
        {/*    <Dashboard/>*/}
        {/*</Route>*/}
    </Switch>
);

export default Routes;