import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchNewStatus,
  selectStatus,
  changeState,
  fetchLocation
} from './statusSlice';

import {
  useParams
} from "react-router-dom";

import { Stomp, StompSubscription, messageCallbackType, CompatClient } from '@stomp/stompjs';
import SockJS from 'sockjs-client'

import styles from './Status.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns'
import { properties } from '../../app/properties'


const useStomp = (locationCode: string, wsUrl: string) => {
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const onMessageReceive: messageCallbackType = (msg => {
    console.log(msg);
    dispatch(changeState(JSON.parse(msg.body)));
  });

  var subscription: StompSubscription;
  var stompClient: CompatClient;
  var onStopConnect = function (frame: any) {
    setConnected(true);
    setError(false);
    console.log('Connected: ' + frame);
    subscription = stompClient.subscribe('/topic/status/' + locationCode, onMessageReceive);
  };

  var onStompError = function (frame: any) {
    setError(true);
    console.log('error: ' + frame);
  }

  var onStompClose = function (closeEvent: any) {
    setError(false);
    setConnected(false);
  }

  var connect = () => {
    stompClient = Stomp.over(function () { return new SockJS(wsUrl) });
    stompClient.reconnect_delay = 3000;
    stompClient.connect({}, onStopConnect, onStompError, onStompClose);
  }

  var cleanUp = () => {
    console.log("Closing All")
    if (subscription) {

      subscription.unsubscribe()
    }
    if (stompClient !== null) {
      stompClient.disconnect();
    }
    setConnected(false)
  }


  useEffect(() => {
    dispatch(fetchNewStatus(locationCode));
    connect();
    return cleanUp;
  }, []);
  return { connected, error };

}


export function Status() {
  const statuses = useSelector(selectStatus);
  const dispatch = useDispatch();
  let { locationCode } = useParams();

  let stompState = useStomp(locationCode, properties.serverUrl + 'qmunity-websocket');
  useEffect(() => { dispatch(fetchLocation(locationCode)) }, [dispatch, locationCode])

  return (<div>
    <div className={styles.title}>{statuses.location && statuses.location.location ? statuses.location.location + ' - ' + statuses.location.position : 'Nepostojeća lokacija'} </div>
    <div>
      {
        statuses.status.map(element =>
          <div key={element.queueName + "-" + element.number} className={styles.value + ' ' + styles.backgroundAnimation} ref={React.createRef()}>
            {element.queueName} - {("" + element.number).padStart(3, '0')}
          </div>
        )
      }
      {!stompState.connected ? (
        <button
          className={styles.button}
          aria-label="Increment value"
          onClick={() => dispatch(fetchNewStatus(locationCode))}
        >
          Refresh
        </button>
      )
        : (
          <div className={styles.status} ref={React.createRef()}><FontAwesomeIcon icon="broadcast-tower" /> {statuses.lastTime ? format(statuses.lastTime, 'dd.MM.yyyy HH:mm:ss') : ""}</div>
        )}
    </div>

  </div>
  );
}
